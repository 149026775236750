import React, { useMemo } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { Carousel } from 'antd';

export const CustomCarousel = styled(Carousel)`
  .slick-dots li.slick-active button {
    background-color: #725CFF; /* 设置当前指示点的颜色 */
  }

  .slick-dots li button {
    background-color: #D9D9D9; /* 设置未激活指示点的颜色 */
  }
`;

const CarouselItem = styled.div`
  display: flex;
  justify-content: center;  
  align-items: center;   
`;

const FeaturesContent = () => {
  const { t } = useTranslation('common');

  const dataList = [
    {
      imgPath: '/images/home/feature-1.webp',
      title: t('100_Anonymous'),
      desc: t('100_Anonymous_Desc'),
    },
    {
      imgPath: '/images/home/feature-2.webp',
      title: t('ComprehensiveData'),
      desc: t('ComprehensiveData_Desc'),
    },
    {
      imgPath: '/images/home/feature-3.webp',
      title: t('Deep_AI_Insights'),
      desc: t('Deep_AI_Insights_Desc'),
    },
    {
      imgPath: '/images/home/feature-4.webp',
      title: t('Identify_Suspected_Accounts'),
      desc: t('Identify_Suspected_Accounts_Desc'),
    },
    {
      imgPath: '/images/home/feature-5.webp',
      title: t('Easy_to_Use'),
      desc: t('Easy_to_Use_Desc'),
    },
    {
      imgPath: '/images/home/feature-6.webp',
      title: t('CompletelyLegal'),
      desc: t('CompletelyLegal_Desc'),
    },
  ];

  const cardItem = (imgPath: string, title: string, desc: string) => {
    return (
      <div className={styles.cardContainer} key={title}>
        <img src={imgPath} alt="icon" className={styles.icon} />
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.desc} dangerouslySetInnerHTML={{ __html: desc }} />
      </div>
    );
  };

  const getContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <h2>{t('Dolphin Radar Advantages')}</h2>

        {typeof window !== 'undefined' && <>
          {window.innerWidth > 900 ? (
            <div className={styles.container}>
              {dataList.map((item, index) => {
                return cardItem(item.imgPath, item.title, item.desc);
              })}
            </div>) : (
            <div className={styles.mobileContainer}>
              <CustomCarousel autoplay lazyLoad="progressive">
                {dataList.map((item, index) => {
                  return <CarouselItem key={index}>
                    {cardItem(item.imgPath, item.title, item.desc)}
                  </CarouselItem>
                })}
              </CustomCarousel>
            </div>)}
        </>}
      </div>
    );
  }, [dataList]);

  return <>{getContent}</>;
};

export default FeaturesContent;
